import {v4 as uuidv4} from 'uuid';
import {
  NotificationType,
  UniversalNotificationHandler,
} from '../../../../services/globalNotification/universalNotificationHandler';
import {EditorGridViewerData} from '../../elements/grid/GridType';
import {EditorBlock} from '../../types/EditorBlock';
import {SaveBlocks} from '../contentEditor/Helpers';

const RecursiveRndID = (block: EditorBlock<EditorGridViewerData>) => {
  block.id = uuidv4();
  block.data.columns.forEach((col) => {
    col.inner.forEach((element) => {
      if (element.type == 'grid') {
        RecursiveRndID(element as EditorBlock<EditorGridViewerData>);
      } else {
        element.id = uuidv4();
      }
    });
  });
};

export const CopyEditorBlocks = (blockId: string) => {
  const data = SaveBlocks(blockId);
  navigator.clipboard.writeText(JSON.stringify(data)).then(() => {
    UniversalNotificationHandler(
      'Copied',
      'Page has been successfully copied to clipboard',
      NotificationType.success,
    );
  });
};

export const PasteEditorBlocks = (
  callback: (data: EditorBlock<EditorGridViewerData>) => void,
) => {
  navigator.clipboard
    .readText()
    .then((data) => {
      const parsed = JSON.parse(data) as EditorBlock<EditorGridViewerData>;
      if (!parsed || !parsed.id) {
        throw new TypeError('Wrong data in a clipboard');
      }
      RecursiveRndID(parsed);
      callback(parsed);
    })
    .catch((error) => {
      UniversalNotificationHandler(
        'Failed',
        error.message,
        NotificationType.danger,
      );
    });
};
