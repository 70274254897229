import classNames from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useGetAllLanguages} from '../../../../api/localization/Localization';
import LoadingEditorBlock from '../../../editor/elements/common/LoadingBlock';
import {SubscribeToPersistStorage} from '../../../editor/types/UseEditor';
import FormButton from '../../../form/button/FormButton';
import Input from '../../../form/input/Input';
import {SaveBlocks} from '../../../editor/service/contentEditor/Helpers';
import {
  NotificationType,
  UniversalNotificationHandler,
} from '../../../../services/globalNotification/universalNotificationHandler';
import {Link, useNavigate} from 'react-router-dom';
import {
  CreateCustomPageDAO,
  TranslationsCreateCustomPageDAO,
} from '../../../../api/customPage/dao/CustomPageDAO';
import {
  useCreateCustomPage,
  useUpdateCustomPage,
} from '../../../../api/customPage/CustomPageMutations';
import {AiOutlineLoading, AiOutlineRollback} from 'react-icons/ai';
import CustomPageTranslationEditor, {
  CustomPageTranslation,
} from './CustomPageTranslationEditor';
import {initLocalSave} from '../../../../services/utils/localSaver';
import Toggle from '../../../form/switch/Toggle';

type CustomPageEditorForm = Omit<CreateCustomPageDAO, 'translations'>;
interface CreateCustomPageProps {
  customPage?: CreateCustomPageDAO;
  customPageId?: string;
}

const CreateCustomPageEditor: React.FC<CreateCustomPageProps> = ({
  customPage,
  customPageId,
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    watch,
    setError,
    setFocus,
    formState: {errors},
  } = useForm<CustomPageEditorForm>({
    defaultValues: {
      ...customPage,
      section: customPage?.section || '',
      subsection: customPage?.subsection || '',
      pageName: customPage?.pageName || '',
    },
  });

  const [currentLanguage, setCurrentLanguage] = useState(0);
  const languages = useGetAllLanguages();

  const [translationsData, setTranslationsData] = useState<{
    [key: string]: CustomPageTranslation;
  }>({});

  const navigation = useNavigate();

  const createCustomPage = useCreateCustomPage({
    onSuccess: () => {
      UniversalNotificationHandler(
        'Created',
        'Created',
        NotificationType.success,
      );
      navigation('/admin/custom-pages');
    },
  });

  const updateCustomPage = useUpdateCustomPage(customPageId || '', {
    onSuccess: () => {
      UniversalNotificationHandler('Saved', 'saved', NotificationType.success);
    },
  });

  useEffect(() => {
    SubscribeToPersistStorage();
  }, []);

  const getSaveData = useCallback(
    (mainFormData: CustomPageEditorForm): CreateCustomPageDAO => {
      const savedBlocks: TranslationsCreateCustomPageDAO[] = [];

      Object.keys(translationsData).forEach((key) => {
        const body = JSON.stringify(SaveBlocks(translationsData[key].blockId));
        savedBlocks.push({
          languageId: translationsData[key].languageId,
          block: {
            rawData: body,
            baseBlockId: translationsData[key].blockId,
          },
          title: translationsData[key].title,
          thumbnailId: translationsData[key].thumbnailId,
        });
      });

      const data: CreateCustomPageDAO = {
        ...mainFormData,
        translations: savedBlocks,
      };

      return data;
    },
    [translationsData],
  );

  const OnSave = useCallback(
    (mainFormData: CustomPageEditorForm) => {
      if (mainFormData.section && !mainFormData.subsection) {
        setError('subsection', {
          message: 'Subsection must be specified because section is set',
        });
        setFocus('subsection');
        return;
      }

      const data = getSaveData(mainFormData);

      if (!customPageId) createCustomPage.mutate(data);
      else updateCustomPage.mutate(data);
    },
    [
      getSaveData,
      customPageId,
      createCustomPage,
      updateCustomPage,
      setError,
      setFocus,
    ],
  );

  const getLink = (pageName: string, section?: string, subsection?: string) => {
    return `https://xreadylab.com/p/${section && section + '/'}${
      subsection && subsection + '/'
    }${pageName && pageName}`;
  };

  const [link, setLink] = useState((): string => {
    const data = getValues();
    return getLink(data.pageName, data.section, data.subsection);
  });

  useEffect(() => {
    const subscription = watch((data) => {
      setLink(getLink(data.pageName || '', data.section, data.subsection));
    });
    return () => subscription.unsubscribe();
  }, [getValues, watch]);

  if (!languages.isSuccess) return <LoadingEditorBlock />;
  return (
    <>
      <div className="flex gap-2">
        <Link to="/admin/custom-pages">
          <FormButton>
            <div className="flex justify-between items-center gap-2">
              <AiOutlineRollback /> Back
            </div>
          </FormButton>
        </Link>
        {customPageId && (
          <Link to={`/admin/custom-pages/${customPageId}`} target="_blank">
            <FormButton>Open</FormButton>
          </Link>
        )}
      </div>
      <form className="my-4" onSubmit={handleSubmit(OnSave)}>
        <div className="gap-2 grid">
          <div>{link}</div>
          <div className="flex gap-2 flex-col sm:flex-row">
            <Input
              className="grow"
              label="Page name"
              name="pageName"
              type="text"
              registration={{register, errors}}
              options={{
                required: 'Set page name',
                pattern: {
                  value: /^[A-Za-z\d\-\_\+\=\,\(\)]+$/,
                  message: 'Only - _ + = , ( ) symbols allowed',
                },
              }}
            />
            <Input
              className="grow"
              label="Section"
              name="section"
              type="text"
              options={{
                pattern: {
                  value: /^[A-Za-z\d\-\_\+\=\,\(\)]+$/,
                  message: 'Only - _ + = , ( ) symbols allowed',
                },
              }}
              registration={{register, errors}}
            />
            <Input
              className="grow"
              label="Subsection"
              name="subsection"
              type="text"
              options={{
                pattern: {
                  value: /^[A-Za-z\d\-\_\+\=\,\(\)]+$/,
                  message: 'Only - _ + = , ( ) symbols allowed',
                },
              }}
              registration={{register, errors}}
            />
            <Toggle
              className="flex-1"
              name="isPublic"
              label="Is public"
              setValue={setValue}
              getValues={getValues}
            />
          </div>
        </div>
        <hr className="my-4" />
        <div className="grid gap-2">
          <div className="flex gap-2 mb-2">
            {languages.data.map((el, index) => (
              <FormButton
                key={el.id}
                onClick={() => setCurrentLanguage(index)}
                selected={index == currentLanguage}
              >
                {el.name}
              </FormButton>
            ))}
          </div>
          {languages.data.map((el, index) => (
            <CustomPageTranslationEditor
              key={el.id}
              language={el}
              className={classNames(currentLanguage !== index && 'hidden')}
              onFormUpdated={(data) => {
                setTranslationsData((old) => ({
                  ...old,
                  [data.languageId]: data,
                }));
              }}
              originalTranslation={customPage?.translations.find(
                (x) => x.languageId == el.id,
              )}
            />
          ))}
          <div className="flex gap-2">
            <FormButton
              type="submit"
              className="bg-pink-500 text-white flex-1 hover:text-black hover:font-semibold"
              disabled={createCustomPage.isLoading}
            >
              {!createCustomPage.isLoading ? (
                'Save'
              ) : (
                <div className="flex w-full justify-center items-center">
                  <AiOutlineLoading
                    className="animate-spin font-semibold"
                    size={24}
                  />
                </div>
              )}
            </FormButton>
            <FormButton
              onClick={() => {
                const data = getSaveData(getValues());
                initLocalSave(data, `custom_page-${customPageId || 'new'}`);
              }}
              className="flex-shrink"
            >
              Save local
            </FormButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateCustomPageEditor;
