import React, {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {SketchfabAnnotationDTO} from '../../../../api/sketchfab/dto/SketchfabModelDTO';
import {EditorElementProps} from '../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../types/UseEditor';
import LoadingEditorBlock from '../common/LoadingBlock';
import {EditorSketchfabData, SketchfabModelData} from './SketchfabType';
import SketchfabPopupEditorVersion from '../../common/sketchfabPopup/SketchfabPopupEditorVersion';
import FormButton from '../../../form/button/FormButton';
import SketchfabViewerEditorVersion from '../../../sketchfabViewer/SketchfabViewerEditorVersion';
import AllSketchfabPopup from '../../common/sketchfabPopup/AllSketchfabPopap.';

const SketchfabEditor: React.FC<EditorElementProps<EditorSketchfabData>> = (
  originBlock,
) => {
  const setBlockMutation = useUpdateEditorBlock<EditorSketchfabData>();

  const {setValue, watch} = useForm<EditorSketchfabData>({
    defaultValues: {...originBlock.data},
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...(updatedData as EditorSketchfabData),
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  // генерируем массив разрешенных к показу стандартных аннотаций, т.е. только те, у которых параметр isShown = true
  const arrayAnnotationForShow = useCallback(() => {
    const array: number[] = [];
    originBlock.data.sketchfabData.translation.annotations.forEach(
      (annotation: SketchfabAnnotationDTO, index: number) => {
        if (annotation.isShown) {
          array.push(index);
        }
      },
    );
    return array;
  }, [originBlock.data.sketchfabData.translation.annotations]);

  // // autoplay - это параметр, который хранится не на беке, а в блоке originBlock.data.. С бека мы получаем по originBlock.data.id данные о 3д-модели: аннотации, анимации и др. но не autoplay и не defaultAnimation. Эти 2 параметра мы храним на уровне блоков в редакторе.
  // const changeAutoPlay = useCallback(() => {
  //   setValue('autoplay', !originBlock.data.autoplay);
  // }, [originBlock.data.autoplay, setValue]);

  // // isShowBackground - отвечает делать ли задний фон 3д-модели прозрачным или черным. true - прозрачный, false - черный фон
  // const changeIsShowBackground = useCallback(() => {
  //   setValue('isShowBackground', !originBlock.data.isShowBackground);
  // }, [originBlock.data.isShowBackground, setValue]);

  // // ф-ия меняет дефолтную анимацию, напоминаю, что она хранится на уровне блока
  // const changeDefaultAnimation = useCallback(
  //   (id: string) => {
  //     setValue('defaultAnimation', id);
  //   },
  //   [setValue],
  // );

  // // ф-ия меняет fullData, потом мы fullData отправляем для сохранения изменений
  // const changeFullData = useCallback(
  //   (newData: SketchfabDataForEditor) => {
  //     setValue('sketchfabData', newData);
  //   },
  //   [setValue],
  // );

  const OnSketchfabModelUpdated = useCallback(
    (sketchfabModel: SketchfabModelData) => {
      setValue('sketchfabData', sketchfabModel);
    },
    [setValue],
  );

  if (!originBlock || !originBlock.data) return <LoadingEditorBlock />;
  if (!originBlock.data.sketchfabData)
    return (
      <div className="text-2xl p-2 sm:p-5">
        <AllSketchfabPopup
          onModelSelected={(sketchfab) => {
            setValue('sketchfabData', sketchfab);
          }}
        >
          <FormButton>Popap All Sketchfabs</FormButton>
        </AllSketchfabPopup>
      </div>
    );

  return (
    <div className="text-2xl p-2 sm:p-5">
      <div className="flex gap-2 mb-4">
        <SketchfabPopupEditorVersion
          fullData={originBlock.data.sketchfabData}
          setData={OnSketchfabModelUpdated}

          // setFullData={changeFullData}
          // autoplay={originBlock.data.autoplay}
          // defaultAnimation={originBlock.data.defaultAnimation}
          // changeAutoPlay={changeAutoPlay}
          // changeDefaultAnimation={changeDefaultAnimation}
          // isShowBackground={originBlock.data.isShowBackground}
          // changeIsShowBackground={changeIsShowBackground}
        >
          <FormButton>Edit model</FormButton>
        </SketchfabPopupEditorVersion>
        <AllSketchfabPopup
          onModelSelected={(sketchfab) => {
            setValue('sketchfabData', sketchfab);
          }}
        >
          <FormButton>Select another model</FormButton>
        </AllSketchfabPopup>
      </div>
      <div className="flex flex-col items-center">
        <div className="simulation-video relative w-full">
          {originBlock.data.sketchfabData.modelId ? (
            <SketchfabViewerEditorVersion
              modelId={originBlock.data.sketchfabData.modelId}
              className="w-full h-full"
              autostart={originBlock.data.sketchfabData.autoplay}
              annotations={
                originBlock.data.sketchfabData.translation.annotations
              }
              customAnnotations={
                originBlock.data.sketchfabData.translation.customAnnotations
              }
              showOnlyAnnotations={arrayAnnotationForShow()}
              currentAnimationUID={
                originBlock.data.sketchfabData.defaultAnimation
              }
              isShowBackground={originBlock.data.sketchfabData.isShowBackground}
            />
          ) : (
            <LoadingEditorBlock />
          )}
        </div>
        {/* {originBlock.data.sketchfabData && (
          <SketchfabPopupEditorVersion
            fullData={originBlock.data.sketchfabData}
            setFullData={changeFullData}
            autoplay={originBlock.data.autoplay}
            defaultAnimation={originBlock.data.defaultAnimation}
            changeAutoPlay={changeAutoPlay}
            changeDefaultAnimation={changeDefaultAnimation}
            isShowBackground={originBlock.data.isShowBackground}
            changeIsShowBackground={changeIsShowBackground}
          />
        )} */}
      </div>
    </div>
  );
};

export default SketchfabEditor;
