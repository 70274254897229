import {memo} from 'react';

export const AboutUsPage = memo(() => {
  return (
    <div className={'flex flex-col gap-4'}>
      <div
        className={
          'mb-5 md:my-10 font-bold text-2xl md:text-3xl text-center uppercase text-indigo-900'
        }
      >
        Иммерсивное обучение для STEM-образования
      </div>

      <div className={'flex flex-col md:flex-row gap-8 '}>
        <div className={'flex flex-col gap-4'}>
          <div>
            Компания ООО ‘Икс Рэди Лэб” основана в 2019 году учеными, педагогами
            и разработчиками игр из ведущих технических университетов и
            компаний, чтобы помочь детям понять абстрактные научные темы с
            помощью обучающих игр.
          </div>

          <div>
            Наша команда XR-специалистов с более чем десятилетним опытом создает
            захватывающие образовательные симуляции в VR и Web-форматах,
            предназначенные для учащихся школ.
          </div>

          <div>
            Нам доверяют более 700 школ в 10 странах мира, получившие доступ к
            крупнейшей в мире библиотеке интерактивных VR-и web симуляций,
            полностью соответствующих международным образовательным стандартам.
          </div>

          <div>
            Благодаря нашему подходу уже более 150 000 студентов открыли для
            себя новый формат обучения — через практику, где ошибки становятся
            естественной частью познания. Наши виртуальные лаборатории
            вдохновляют на исследования, развивают критическое мышление,
            креативность и делают обучение не только эффективным, но и
            по-настоящему увлекательным.
          </div>
        </div>
        <div className={'hidden md:block'}>
          <img src={'/image-about-us.jpg'} />
        </div>
      </div>

      <div>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ИКС РЭДИ ЛЭБ"</div>

      <div className={'flex flex-col md:flex-row gap-4'}>
        <div>ОРН: 1123559</div>
        <div>ИНН: 9731053156ФНС</div>
        <div>ОГРН: 1197746602822ФНС</div>
        <div>КПП: 773101001</div>
      </div>
    </div>
  );
});
