import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useRequestDemoForm} from '../../api/contacts/ContactsMutations';
import {RequestDemoDTO} from '../../api/contacts/dto/RequestDemoDTO';
import Button from '../../components/form/button/Button';
import Input from '../../components/form/input/Input';
import ListBox, {ListBoxOption} from '../../components/form/listbox/Listbox';
import {API_REGION} from '../../services/flagProvider/FlagProvider';

type SingUp = {
  requestOption: string;
} & Omit<RequestDemoDTO, 'locale'>;

const RequestDemo: React.FC = () => {
  const {t} = useTranslation('contactsPage');
  const naviagator = useNavigate();

  const requestOption: ListBoxOption<string>[] = [
    {
      label: t('request-type.in-classroom'),
      value: 'In Classroom',
    },
    {
      label: t('request-type.at-home'),
      value: 'At home',
    },
    {
      label: t('request-type.distributor'),
      value: 'I am a distributor',
    },
  ];

  const contactUsForm = useRequestDemoForm({
    onSuccess: () => naviagator('/contacts/success'),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<SingUp>({
    mode: 'onSubmit',
  });

  const onSubmit = (data: SingUp) => {
    contactUsForm.mutate({
      ...data,
      locale: `Region: ${API_REGION || 'unknown'}; Purpose: ${
        data.requestOption
      }`,
    });
  };

  return (
    <div className="mt-2 lg:mt-24 mx-auto mb-48 contacts-form">
      <h1 className="font-bold text-2xl lg:text-6xl text-center uppercase text-indigo-900 mb-2 lg:mb-14">
        {t('request-demo-title')}
      </h1>
      <div className="mx-auto mt-0 mb-2 lg:mb-16 font-light text-sm lg:text-lg text-center text-black contacts-description">
        {t('request-demo-description')}
      </div>
      <form className="px-2 lg:px-40" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={t('form.name')}
          name="name"
          registration={{register, errors}}
          className="mt-3"
          options={{
            required: (t('form.nameError') as string | undefined) || 'Required',
          }}
        />
        <div className="grid md:grid-cols-2 gap-2">
          <Input
            label={t('form.work-email')}
            name="email"
            registration={{register, errors}}
            className="mt-3"
            options={{
              required:
                (t('form.emailError') as string | undefined) || 'Required',
            }}
            type="email"
          />
          <Input
            label={t('form.phone')}
            name="phone"
            registration={{register, errors}}
            className="mt-3"
          />
        </div>
        <ListBox
          name="requestOption"
          className="flex-1 mt-2"
          options={requestOption}
          label="Where do you want to use simulations?"
          setValue={setValue}
          getValues={getValues}
        />
        <Input
          label={t('form.website')}
          name="website"
          registration={{register, errors}}
          className="mt-3"
        />
        <Button type="submit" id="signUp1" className="w-full mt-4">
          {t('form.send')}
        </Button>
      </form>
    </div>
  );
};

export default RequestDemo;
